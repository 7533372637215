<template>
  <div class="service_details_container">
    <img class="title_image" src="../../assets/img_shipping.png" />
    <h4>START SHIPPING - QUICK BOOKING</h4>
    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <!-- <section>
        <h2>CHOOSE ENTRY METHOD</h2>
        <div class="radio_wrapper1">
          <label>
            <input type="radio" name="entrychoose" value="single" id="single" v-model="draft.entry_type" />
            <span>Single</span>
          </label>
          <label>
            <input type="radio" name="entrychoose" value="batch" id="batch" v-model="draft.entry_type"  :disabled="auth().user_type == 'user' ">
            <span>Batch</span>
          </label>
        </div>
      </section> -->
      <section>
        <h2>SERVICE OPTIONS</h2>
        <div class="form_section1">
          <table>
            <tr>
              <td>
                <ValidationProvider name="requester name" rules="required|max:100" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Requester Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character Limit 100" v-model="draft.requester_name" :maxlength="max4" :class="errors[0] ? 'warning-border':''" :disabled="auth().user_type == 'user' "/>
                  </div>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider name="unit name" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Units / Branch
                    <span>*</span>
                  </label>
                  <select v-model="draft.branch_id" :class="errors[0] ? 'warning-border':''" :disabled="auth().user_type == 'user' ">
                    <option value="">Select a branch</option>
                    <option v-for="(branch,i) in branches" :key="'branches'+i" :value="branch.id">{{branch.name}}</option>
                  </select>
                </div>
                </ValidationProvider>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Request Date
                    <span>*</span>
                  </label>
                  <flat-pickr
                    class="date_for_pos_fixed"
                    v-model="draft.request_date"
                    :config="configDate"
                    placeholder="Date"
                    name="birthdate"
                  ></flat-pickr>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <ValidationProvider name="type of good" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Type of Goods
                    <span>*</span>
                  </label>
                  <select v-model="draft.type_of_good" @change="fetchPackageType" :class="errors[0] ? 'warning-border':''">
                    <option value="" disabled>Select package type</option>
                    <option v-for="(good,i) in goods" :key="'goods'+i" :value="good.id">{{good.type}}</option>
                  </select>
                </div>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider name="mode of dispatch" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Mode of Dispatch
                    <span>*</span>
                  </label>
                  <select v-model="draft.mode_of_dispatch" :class="errors[0] ? 'warning-border':''">
                    <option value="" disabled>Select mode of dispatch</option>
                    <option v-for="(dispatch,i) in dispatches" :key="'dispatches'+i" :value="dispatch.id">{{dispatch.name}}</option>
                  </select>
                </div>
                </ValidationProvider>
              </td>
              <td></td>
            </tr>
          </table>
        </div>
        <div class="form_section1" v-if="draft.mode_of_dispatch">
          <label class="radio_with_img_labels" v-for="(provider,i) in providers" :key="'provider'+i">
            <!-- <a class="view_rate_card" v-if="provider.rate_card" :href="provider.rate_card" target="_blank">
              <img :src="provider.logo" :alt="provider.name" />
            </a> -->
            <div v-if="provider.rate_card != 'null' ">
              <a class="view_rate_card" :href="provider.rate_card" target="_blank">
              <img :src="provider.logo" :alt="provider.name" />
            </a>
            </div>
            <div v-else>
              <img :src="provider.logo" :alt="provider.name" />
            </div>
            <input type="radio" :checked="provider.id == draft.provider_id" name="provider" :ref="provider.id" @click="selectRateCard(provider)" />
          </label>
        </div>
      </section>

      <section>
        <h2>DELIVERY OPTIONS</h2>
        <div class="form_section1">
          <div class="special_panel">
            <header>Service Name</header>
            <div class="sp_body">
              <div class="a_part">
                <ValidationProvider name="type of good" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper sp">
                  <label>
                    Choose a delivery service
                    <span>*</span>
                  </label>
                  <select v-model="draft.service_id"  :class="errors[0] ? 'warning-border':''">
                    <option value="">Select service</option>
                    <option v-for="(service,i) in services" :value="service.id" :key="'services'+i">{{service.name}}</option>
                  </select>
                </div>
                </ValidationProvider>
                <div class="service_logo">
                  <img :src="provider_details.logo" :alt="provider_details.name" />
                </div>
              </div>
              <div class="a_part">
                <div class="infos">{{provider_details.description}}</div>
                <div v-if="provider_details.rate_card != 'null' ">
                  <a class="view_rate_card" v-if="provider_details.rate_card  " :href="provider_details.rate_card" target="_blank">
                    View Rate Card
                    <i class="material-icons">receipt</i>
                  </a>
                </div>
                <!-- <div v-else>
                    <h4>No rate card found</h4>
                </div> -->
              </div>
            </div>
          </div>
          <table>
            <tr>
              <td>
                <!-- <ValidationProvider name="Customer reference number" rules="required|max:30" v-slot="{ errors }" slim> -->
                <div class="label_and_element_wrapper">
                  <label>
                    Customer reference number
                  </label>
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft.reference_no" :maxlength="max"/>
                </div>
                <!-- </ValidationProvider> -->
              </td>
              <td>
               <!--  <ValidationProvider name="PTAEO" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    PTAEO
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter PTAEO" v-model="draft.ptaeo" @focus="ptaeo_suggestion" @keyup="ptaeo_suggestion" @blur="hideptaeo_suggestion = false" :class="errors[0] ? 'warning-border':''"/>
                  <div class="dynamic_data_wrapper" v-show="hideptaeo_suggestion">
                    <span 
                          v-for="(ptaeosuggest,i) in select_ptaeo_suggestions" :key="'ptaeo_suggestion_list_'+i"
                          @mousedown="ptaeoSuggestionSelected(ptaeosuggest.ptaeo)"
                          >
                          {{ ptaeosuggest.ptaeo }}
                        </span>
                  </div>
                </div>
                </ValidationProvider> -->
              </td>
            </tr>
          </table>
    
      <!--     <label class="lone_chk_label">
            <input name="usertype" type="radio" id="standard" v-model="has_standard" value="3"/>
            <span>Standard</span>
          </label>
          <label class="lone_chk_label">
            <input name="usertype" type="radio" v-model="draft.has_priority" value="1"/>
            <span>Priority Importance</span>
          </label>
          <label class="lone_chk_label">
            <input name="usertype" type="radio" v-model="draft.has_emergency" value="2"/>
            <span>Emergency</span>
          </label> -->

        </div>
      </section>

      <h4>ADDRESS DETAILS</h4>
      <section>
        <div class="address_details_wrapper">
          <header>
            <h2>PICKUP DETAILS</h2>
          </header>
          <div class="form_section1">
            <table>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="sender name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Full Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_name"  :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'" :disabled="auth().user_type == 'user'" @focus="show_pickup_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max"/>
                      <div class="dynamic_data_wrapper" v-if="show_pickup_suggestion">
                        <span 
                          v-for="(address,i) in filteredPickupAddress" 
                          :key="'pickup_address_'+i"
                          @click="populatePickupAddress(address)"
                          >
                          {{address.name}}
                        </span>
                      </div>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="pickup company name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Company Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_company" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max" :disabled="auth().user_type == 'user' "/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="pickup branch name" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Branch Name
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_branch" autocomplete="new-password" :maxlength="max" :disabled="auth().user_type == 'user' "/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="pickup address_1" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 1
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.pickup_address_1" autocomplete="new-password" :maxlength="max1" :class="errors[0] ? 'warning-border':''" :disabled="auth().user_type == 'user' "/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="pickup address_2" rules="required|max:60" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 2
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_address_2" autocomplete="new-password" :maxlength="max1" :class="errors[0] ? 'warning-border':''" :disabled="auth().user_type == 'user' ">
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="pickup suburb" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Suburb
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_suburb" autocomplete="new-password" :maxlength="max" :disabled="auth().user_type == 'user' "/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <ValidationProvider name="pickup city" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      City
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_city"  autocomplete="new-password" :maxlength="max" :class="errors[0] ? 'warning-border':''" :disabled="auth().user_type == 'user' "/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="pickup state" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      State / Province
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_state" autocomplete="new-password" :maxlength="max" :class="errors[0] ? 'warning-border':''" :disabled="auth().user_type == 'user' "/>
                  </div>
                </ValidationProvider>
                </td>
                <td>
                  <!-- <ValidationProvider name="pickup postal_code" rules="required|max:10" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Postal Code
                    </label>
                    <input type="text" placeholder="Enter Character Limit 10" v-model="draft.pickup_postal_code" autocomplete="new-password" :maxlength="max2" :disabled="auth().user_type == 'user' "/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="pickup country" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Country
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_country" autocomplete="new-password" :maxlength="max" :disabled="auth().user_type == 'user' "/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="pickup phone" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Phone
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_phone" autocomplete="new-password" :maxlength="max" :disabled="auth().user_type == 'user' "/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <!-- <ValidationProvider name="pickup mobile" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Mobile
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_mobile" autocomplete="new-password" :maxlength="max" :disabled="auth().user_type == 'user' "/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="pickup email" rules="required|max:60" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Email
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.pickup_email" autocomplete="new-password" :maxlength="max1" :class="errors[0] ? 'warning-border':''" :disabled="auth().user_type == 'user' "/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="address_details_wrapper">
          <header>
            <h2>DELIVERY DETAILS</h2>
            <a class="yellow_bt" @click="viewAddressBook('delivery')">VIEW ADDRESS BOOK
              <i class="material-icons">account_box</i>
            </a>
            <div class="selected_count">
              <i class="material-icons">account_circle</i>
              <span>{{ delivery_addresses_count }} Selected</span>
            </div>
          </header>
          <div class="form_section1">
            <table>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Full Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_name" @focus="show_destination_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max"  :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'"/>
                      <div class="dynamic_data_wrapper" v-if="show_destination_suggestion">
                        <span 
                          v-for="(address,i) in filteredDeliveryAddress" 
                          :key="'delivery_address_'+i"
                          @click="populateDeliveryAddress(address)"
                          >
                          {{address.name}}
                        </span>
                      </div>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery company" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Company Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_company" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="delivery branch" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Branch Name
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_branch" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery address_1" rules="required|max:60" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 1
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.delivery_address_1" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max1"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery address_2" rules="required|max:60" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 2
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.delivery_address_2" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max1"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="delivery suburb" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Suburb
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_suburb" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <ValidationProvider name="delivery city" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      City
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_city" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="delivery state" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      State / Province
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_state" autocomplete="new-password" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
                  </div>
                </ValidationProvider>
                </td>
                <td>
                  <!-- <ValidationProvider name="delivery postal_code" rules="required|max:10" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Postal Code
                    </label>
                    <input type="text" placeholder="Enter Character Limit 10" v-model="draft.delivery_postal_code" autocomplete="new-password" :maxlength="max2"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="delivery country" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Country
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_country" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="delivery phone" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Phone
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_phone" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <!-- <ValidationProvider name="delivery mobile" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Mobile
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_mobile" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery email" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Email
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.delivery_email" autocomplete="new-password" :maxlength="max1"  :class="errors[0] ? 'warning-border':''"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
      <section>
        <h2>SERVICE DETAILS</h2>
        <div class="form_section1">
          <table class="tablce_cell_adj">
            <tr>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Item Reference
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft.item_reference" :maxlength="max" />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Package
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft.item_type" :maxlength="max" />
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Weight(Kg)
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5"  v-model="draft.item_weight" :maxlength="max1"/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Items
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5"  v-model="draft.item_qty" :maxlength="max1"/>
                </div>
              </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      L(m)
                      <span></span>
                    </label>
                    <!-- <input type="text" placeholder="Enter Character Limit 5" v-model="draft1.item_length" :maxlength="max1" :disabled="volume == 0 " /> -->

                        <input type="text" placeholder="Enter Character Limit 5" v-model="draft.item_length" :maxlength="max1"/>
                        
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      W(m)
                      <span></span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 5"  :value="draft.item_width" @change="updateWidth" :maxlength="max1" />
                    
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      H(m)
                      <span></span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 5" :value="draft.item_height" @change="updateHeight" :maxlength="max1"/>
                    
                  </div>
                </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    m3
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5"  v-model="draft.item_m3" :maxlength="max1" disabled/>
                  
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Cubic kg
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5" v-model="draft.item_cubic_kg" :maxlength="max1"/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Charge Unit
                    <span></span>
                  </label>
                  <input type="text" placeholder="Enter Character Limit 5"  v-model="draft.item_charge_unit" :maxlength="max1"/>
                </div>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    DG
                    <span></span>
                  </label>
                  <input type="checkbox" v-model="draft.item_is_dg" true-value="1" false-value="0" />
                  
                </div>
              </td>
              <td>
                <!-- <div class="label_and_element_wrapper">
                  <label>
                    Food
                    <span></span>
                  </label>
                  <input type="checkbox" v-model="draft.item_is_food" true-value="1" false-value="0"  />
                </div> -->
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section>
        <div class="address_details_wrapper">
          <h2>DESCRIPTION OF GOODS</h2>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <!-- <ValidationProvider name="goods description" rules="required|max:30" v-slot="{ errors }" slim>-->
                    <div class="label_and_element_wrapper"> 
                    <label>
                      Enter description of goods
                      <!-- <span>*</span> -->
                    </label>
                    <textarea placeholder="Enter Character Limit 70" v-model="draft.goods_description" :maxlength="max3"></textarea>
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="address_details_wrapper">
          <h2>PICKUP INSTRUCTIONS</h2>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <!-- <ValidationProvider name="pickup instructions" rules="required|max:30" v-slot="{ errors }" slim> -->
                    <div class="label_and_element_wrapper">
                    <label>
                      Enter pickup instructions
                      <!-- <span>*</span> -->
                    </label>
                    <textarea placeholder="Description here Character Limit 70" v-model="draft.pickup_instructions" :maxlength="max3"></textarea>
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    </ValidationObserver>
    <section class="centered">
      <a class="link_bt bt_cancel" @click="$router.push('/pages/Dashboard')">Cancel</a>
      <a class="link_bt bt_draft" @click="draftsubmit"  :disabled="save_btn.disabled">{{save_btn.value}}</a>
      <a class="link_bt bt_save" @click="submit" :disabled="submit_btn.disabled">{{submit_btn.value}}</a>
    </section>

    <div class="black-overlay" v-if="address_book.pickup || address_book.delivery">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal 
            v-if="address_book.delivery" 
            @closeModal="closeModal" 
            type="delivery" 
            :callfrom="draft.entry_type"
            @selectedAddress="populateDeliveryAddress"
            :selectedAddresses="draft.delivery_address"
          />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignment
            @closeModal="closeConsignment"
            :booking_id="viewconsignmentpopup_booking_id"
            v-if="viewconsignmentpopup_booking_id"
          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AddressBookModal from "./AddressBookModal.vue";
import moment from "moment";
import ViewConsignment from "./ViewConsignment.vue";
export default {
  name: "QuickCourierBooking",
  components: {
    flatPickr,
    AddressBookModal,
    ViewConsignment
  },
  computed:{
    filteredPickupAddress(){
      return this.address_list.filter(element => {
        if(element.name.toLowerCase().includes(this.pickup_name.toLowerCase())){
          return element;
        }
      })
    },
    filteredDeliveryAddress(){
      return this.address_list.filter(element => {
          console.log(element);
        if(element.name.toLowerCase().includes(this.draft.delivery_name.toLowerCase())){
          console.log(element);
          return element;
        }
      })
    }
  },
  data() {
    return {
      has_standard: 1 ,
      viewconsignmentpopup_booking_id: "",
      delivery_addresses_count:0,
      max: 30,
      max1: 60,
      max2: 10,
      max3: 70,
      max4: 100,
      configDate: {
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      showEditContact: false,
      type : "",
      weight : "",
      qty : "",
      length : "",
      width : "",
      height : "",
      m3 : "",
      cubic_kg : "",
      charge_unit : "",
      is_dg : "",
      is_food : "",
      volume:"",
      draft:{
        request_date:moment(new Date()).format('YYYY-MM-DD'),
        entry_type:"single",
        requester_name:"",
        branch_id:"",
        type_of_good:"",
        mode_of_dispatch:"",
        carrier_code:"",
        provider_id:"",
        service_id:"",
        p:"",
        t:"",
        a:"",
        e:"",
        o:"",
        has_priority:0,
        has_emergency:0,
        reference_no:"",
        temp_pickup_address_id:"",
        pickup_name:"" ,
        pickup_company: "",
        pickup_branch: "",
        pickup_address_1: "",
        pickup_address_2: "",
        pickup_postal_code: "",
        pickup_city: "",
        pickup_suburb: "",
        pickup_state: "",
        pickup_country: "",
        pickup_phone: "",
        pickup_mobile: "",
        pickup_email: "",
        delivery_name:"",
        delivery_company:"",
        delivery_branch:"",
        delivery_address_1:"",
        delivery_address_2:"",
        delivery_postal_code:"",
        delivery_city:"",
        delivery_suburb:"",
        delivery_state:"",
        delivery_country:"",
        delivery_phone:"",
        delivery_mobile:"",
        delivery_email:"",
        goods_description: "",
        pickup_instructions: "",
        delivery_address:[],
        booking_type: "Courier",
        item_reference : "NA",
        item_type : "",
        item_weight : 0,
        item_qty : 1,
        item_length : 0,
        item_width : 0,
        item_height : 0,
        item_m3 : 0,
        item_cubic_kg : 0,
        item_charge_unit : 0,
        item_is_dg : 0,
        item_is_food : 0
      },
      address_book:{
        pickup:false,
        delivery:false
      },
      // hideptaeo_suggestion:false,
      provider_details:"",
      show_pickup_suggestion:false,
      show_destination_suggestion:false,
      pickupAddressCount: 0,
      selectedAddresses: [],
      branches:[],
      dispatches:[],
      goods:[],
      providers:[],
      services:[],
      // select_ptaeo_suggestions:[],
      // ptaeo_details:[],
      address_list:[],
      save_btn:{
        disabled:false,
        value:"SAVE AS DRAFT"
      },
      submit_btn:{
        disabled:false,
        value:"Submit"
      }
    };
  },
  created(){
    this.$emit("breadCrumbData",["Create Booking","Quick Booking"]);

    if(this.draft.has_emergency == 0 && this.draft.has_priority == 0){
      this.has_standard = 3;
    }

    if(this.$route.params.id != undefined ){

      this.axios.get("/api/draft/"+this.$route.params.id)
      .then(response => {
      console.log(response);
          this.delivery_addresses_count = response.data.count;
          this.draft.entry_type=response.data.draft.entry_type;
          this.draft.request_date=response.data.draft.request_date;
          this.draft.requester_name=response.data.draft.requester_name;
          this.draft.branch_id=response.data.draft.branch_id;
          this.draft.type_of_good=response.data.draft.type_of_good;
          this.draft.mode_of_dispatch=response.data.draft.mode_of_dispatch ;
          this.draft.carrier_code=response.data.draft.carrier_code;
          this.draft.provider_id=response.data.draft.provider.id;
          this.draft.service_id=response.data.draft.service_id;
          this.draft.pickup_name=response.data.draft.pickup_name;
          this.draft.pickup_company=response.data.draft.pickup_company;
          this.draft.pickup_branch=response.data.draft.pickup_branch;
          this.draft.pickup_address_1=response.data.draft.pickup_address_1;
          this.draft.pickup_address_2=response.data.draft.pickup_address_2;
          this.draft.pickup_city=response.data.draft.pickup_city;
          this.draft.pickup_state=response.data.draft.pickup_state;
          this.draft.pickup_postal_code=response.data.draft.pickup_postal_code;
          this.draft.pickup_country=response.data.draft.pickup_country;
          this.draft.pickup_phone=response.data.draft.pickup_phone;
          this.draft.pickup_email=response.data.draft.pickup_email;
          this.draft.delivery_name=response.data.draft.delivery_name;
          this.draft.delivery_branch=response.data.draft.delivery_branch;
          this.draft.delivery_company=response.data.draft.delivery_company;
          this.draft.delivery_address_1=response.data.draft.delivery_address_1;
          this.draft.delivery_address_2=response.data.draft.delivery_address_2;
          this.draft.delivery_city=response.data.draft.delivery_city;
          this.draft.delivery_state=response.data.draft.delivery_state;
          this.draft.delivery_suburb=response.data.draft.delivery_suburb;
          this.draft.delivery_postal_code=response.data.draft.delivery_postal_code;
          this.draft.delivery_country=response.data.draft.delivery_country;
          this.draft.delivery_phone=response.data.draft.delivery_phone;
          this.draft.delivery_email=response.data.draft.delivery_email;
          this.draft.reference_no=response.data.draft.reference_no;
          this.draft.goods_description=response.data.draft.goods_description;
          this.draft.pickup_instructions=response.data.draft.pickup_instructions;
          
          if(response.data.address_book_id && response.data.address_book_id.length > 0){
            this.mapDeliveryAddresses(response.data.address_book_id,false);
            this.draft.address_book_id = response.data.address_book_id[0].id ? response.data.address_book_id[0].id : "";
          }else{
            this.draft.address_book_id = "sbdhcbwkds";
          }

          
          if(this.draft.type_of_good){
            this.axios.get("/api/goods/"+this.draft.type_of_good)
            .then(response => {
              console.log(response);
                this.dispatches = response.data.goods.modes;
                if(this.dispatches.length > 0){
                  this.draft.mode_of_dispatch = this.dispatches[0].id;
                }
            })
            .catch(error =>{
                console.log(error);
            });
          }
          if(this.draft.carrier_code){
            this.axios.get("/api/provider/"+ this.draft.carrier_code)
            .then(response => {
            console.log(response);
                this.services = response.data.provider.services;
                if(this.services.length > 0){
                  this.draft.service_id = this.services[0].id;
                }
                this.provider_details = response.data.provider;
            })
            .catch(error => {
                console.log(error);
            });
          }



          this.draft.item_type=response.data.draft.goods.type ;

          this.draft.item_weight=response.data.draft.item_weight;

          this.draft.item_length=response.data.draft.item_length;

          this.draft.item_width= response.data.draft.item_width;

          this.draft.item_height=response.data.draft.item_height;

          this.draft.item_m3=response.data.draft.item_m3;

          this.draft.item_cubic_kg= response.data.draft.item_cubic_kg ;

          if(response.data.draft.item_is_dg != null){
            this.draft.item_is_dg=response.data.draft.item_is_dg ;
          } else {
            this.draft.item_is_dg= response.data.draft.goods.is_dg;
          }

          if(response.data.draft.item_is_food != null){
            this.draft.item_is_food=response.data.draft.item_is_food;
          } else {
            this.draft.item_is_food= response.data.draft.goods.is_food;
          }

          this.draft.item_reference=response.data.draft.item_reference ? response.data.draft.item_reference : this.draft.item_reference;

          this.draft.item_qty=response.data.draft.item_qty;

          this.draft.item_charge_unit=response.data.draft.item_charge_unit ? response.data.draft.item_charge_unit : 0;

      })
      .catch(error =>{
        console.log(error);
      });
      
    } else {

      this.axios.get("/api/user/data")
      .then(response => {
        console.log(response);
          this.draft.requester_name = response.data.msg.name;
          this.draft.branch_id = response.data.msg.branch.id;
          this.draft.type_of_good = response.data.msg.goods.id;
          this.fetchDispatch();

          if(this.draft.type_of_good){
            this.axios.get("/api/goods/"+this.draft.type_of_good)
            .then(response => {
              console.log(response);
              this.draft.item_type = response.data.goods.type;
              this.draft.item_weight = response.data.goods.weight;
              this.draft.item_length = response.data.goods.length;
              this.draft.item_width = response.data.goods.width;
              this.draft.item_height = response.data.goods.height;
              this.draft.item_m3 = (this.draft.item_length)*(this.draft.item_width)*(this.draft.item_height);
              this.draft.item_cubic_kg = response.data.goods.cubic_kg;
              this.draft.item_is_dg = response.data.goods.is_dg;
              this.draft.item_is_food = response.data.goods.is_food;
              this.volume = response.data.goods.volume;
            })
            .catch(error => {
              console.log(error);
            });
          }
      })
      .catch(error =>{
        console.log(error);
      });
      this.fetchUserData();
    }
      this.fetchBranch();
      this.fetchPackageType();
      // this.fetchPtaeoDetails();
      this.fetchProviders();
      this.fetchAddresses();
  },
  methods: {
    updateLength(event) {
      this.draft.item_length = event.target.value;
      this.draft.item_m3 =  this.draft.item_length * this.draft.item_width * this.draft.item_height;
    },
    updateWidth(event) {
      this.draft.item_width = event.target.value;
      this.draft.item_m3 =  this.draft.item_length * this.draft.item_width * this.draft.item_height;
    },
    updateHeight(event) {
      this.draft.item_height = event.target.value;
      this.draft.item_m3 =  this.draft.item_length * this.draft.item_width * this.draft.item_height;
    },
    hideSuggestions(){
      setTimeout(() => {
        this.show_pickup_suggestion = false;
        this.show_destination_suggestion = false;
      },200);
    },
    fetchBranch(){
      this.axios.get("/api/branch/")
      .then(response => {
        this.branches = response.data.branches;
      })
      .catch(error => {
        console.log(error);
      });
    },
    fetchPackageType(){
      this.axios.get("/api/goods/")
      .then(response => {
        this.goods = response.data.goods;
          this.fetchDispatch();
          this.selectRateCard();
      })
      .catch(error => {
        console.log(error);
      });
    },
    fetchDispatch(){
      if(this.draft.type_of_good){
        this.axios.get("/api/goods/"+this.draft.type_of_good)
        .then(response => {
          console.log(response);
            this.draft.item_type = response.data.goods.type;
            this.draft.item_weight = response.data.goods.weight;
            this.draft.item_length = response.data.goods.length;
            this.draft.item_width = response.data.goods.width;
            this.draft.item_height = response.data.goods.height;
            this.draft.item_m3 = (this.draft.item_length)*(this.draft.item_width)*(this.draft.item_height);
            this.draft.item_cubic_kg = response.data.goods.cubic_kg;
            this.draft.item_is_dg = response.data.goods.is_dg;
            this.draft.item_is_food = response.data.goods.is_food;
            this.volume = response.data.goods.volume;

            this.dispatches = response.data.goods.modes;
            if(this.dispatches.length > 0){
              this.draft.mode_of_dispatch = this.dispatches[0].id;
            }
        })
        .catch(error =>{
            console.log(error);
        });
      }
    },
    /*fetchPtaeoDetails(){
      this.axios.get("/api/ptaeo/")
      .then(response => {
          this.ptaeo_details = response.data.ptaeo_details;
          this.select_ptaeo_suggestions = this.ptaeo_details;
      })
      .catch(error => {
          console.log("Ptaeo details could not be fetched");
      });
    },
    ptaeo_suggestion(){
      this.hideptaeo_suggestion = true;
      if(this.draft.ptaeo){
        this.select_ptaeo_suggestions = this.ptaeo_details.filter(element => {
          if(element.ptaeo.toLowerCase().includes(this.draft.ptaeo.toLowerCase())){
              return element;
          }
        });
      }else{
          this.select_ptaeo_suggestions = this.ptaeo_details;
      }
    },
    ptaeoSuggestionSelected(ptaeo1){
        this.draft.ptaeo = ptaeo1;
    },*/
    /*fetchProviders(){
      if(this.draft.mode_of_dispatch){
        this.axios.get("/api/dispatch/provider/"+ this.draft.mode_of_dispatch)
        .then(response => {
        console.log(response);
            this.providers = response.data.mode_of_dispatch.providers;
        })
        .catch(error => {
            console.log(error);
        });
      }
    },
    selectRateCard(provider) {
      console.log(provider);
      if(provider != undefined) {
        this.draft.provider_id = provider.id;
        this.draft.carrier_code = provider.carrier_code;
        if(provider.carrier_code){
          this.axios.get("/api/provider/"+ provider.carrier_code)
          .then(response => {
          console.log(response);
              this.services = response.data.provider.services;
              if(this.services.length > 0){
                this.draft.service_id = this.services[0].id;
              }
              this.provider_details = response.data.provider;
          })
          .catch(error => {
              console.log(error);
          });
        }
      } else {
        this.services = "";
        this.provider_details = "";
      }
    },*/
    fetchProviders(){
      if(this.draft.mode_of_dispatch){
        this.axios.get("/api/dispatch/provider/"+ this.draft.mode_of_dispatch)
        .then(response => {
        console.log(response);
            this.providers = response.data.mode_of_dispatch.providers;


            this.axios.get("/api/user/data")
            .then(response => {
              console.log(response);
                if(response.data.msg.is_dbsa == 1){
                  if(this.providers.length > 0){
                    console.log(this.providers[0]);
                    this.draft.provider_id = this.providers[0].id;
                    this.draft.carrier_code = this.providers[0].carrier_code;
                    if(this.providers[0].carrier_code){
                      this.axios.get("/api/provider/"+ this.providers[0].carrier_code)
                      .then(response => {
                      console.log(response);
                          this.services = response.data.provider.services;
                          if(this.services.length > 0){
                            this.draft.service_id = this.services[0].id;
                          }
                          this.provider_details = response.data.provider;
                      })
                      .catch(error => {
                          console.log(error);
                      });
                    }
                  }
                }
            })
            .catch(error =>{
                console.log(error);
            });

              






        })
        .catch(error => {
            console.log(error);
        });
      }
    },
    selectRateCard(provider) {
      console.log(provider);
      if(provider != undefined) {
        this.draft.provider_id = provider.id;
        this.draft.carrier_code = provider.carrier_code;
        if(provider.carrier_code){
          this.axios.get("/api/provider/"+ provider.carrier_code)
          .then(response => {
          console.log(response);
              this.services = response.data.provider.services;
              if(this.services.length > 0){
                this.draft.service_id = this.services[0].id;
              }
              this.provider_details = response.data.provider;
          })
          .catch(error => {
              console.log(error);
          });
        }
      } else {
        this.services = "";
        this.provider_details = "";
      }
    },
    viewAddressBook(type){
      console.log(type);
      Object.keys(this.address_book).map(element => {
        this.address_book[element] = false;
      });
      this.address_book[type] = true;
    },
    fetchUserData(){
      this.loading = true;
      this.axios.get("/api/user/data")
      .then(response => {
        console.log(response);
          this.draft.pickup_name = response.data.msg.name;
          this.draft.pickup_branch = response.data.msg.branch ? response.data.msg.branch.name : "";
          this.draft.pickup_address_1 = response.data.msg.address_1;
          this.draft.pickup_address_2 = response.data.msg.address_2;
          this.draft.pickup_postal_code = response.data.msg.postal_code;
          this.draft.pickup_city = response.data.msg.city;
          this.draft.pickup_suburb = response.data.msg.suburb;
          this.draft.pickup_state = response.data.msg.state;
          this.draft.pickup_country = response.data.msg.country;
          this.draft.pickup_phone = response.data.msg.phone;
          this.draft.pickup_mobile = response.data.msg.mobile;
          this.draft.pickup_email = response.data.msg.email;
          this.draft.pickup_company = response.data.msg.company.name;
          this.show_pickup_suggestion = false;
          // this.loading = false;
      })
      .catch(error =>{
          console.log(error);
      });
    },
    populatePickupAddress(value){
      console.log(value);
      this.draft.temp_pickup_address_id = value.id;
      this.draft.pickup_name = value.name;
      this.draft.pickup_branch = value.branch_name;
      this.draft.pickup_company = value.company_name;
      this.draft.pickup_address_1 = value.address_1;
      this.draft.pickup_address_2 = value.address_2;
      this.draft.pickup_postal_code = value.postal_code;
      this.draft.pickup_city = value.city;
      this.draft.pickup_suburb = value.suburb;
      this.draft.pickup_state = value.state;
      this.draft.pickup_country = value.country;
      this.draft.pickup_phone = value.phone;
      this.draft.pickup_mobile = value.mobile;
      this.draft.pickup_email = value.email;
    },
    /*populateDeliveryAddress(addresses){
      if(this.draft.entry_type == "single"){
        addresses = [addresses];
      }
      let selectedAddresses = addresses[0];
      console.log(selectedAddresses);
      this.draft.delivery_name = selectedAddresses.name;
      this.draft.delivery_company = selectedAddresses.company_name;
      this.draft.delivery_branch = selectedAddresses.branch_name;
      this.draft.delivery_department = selectedAddresses.department ? selectedAddresses.department.department_name : "";
      this.draft.delivery_address_1 = selectedAddresses.address_1;
      this.draft.delivery_address_2 = selectedAddresses.address_2;
      this.draft.delivery_postal_code = selectedAddresses.postal_code;
      this.draft.delivery_city = selectedAddresses.city;
      this.draft.delivery_suburb = selectedAddresses.suburb;
      this.draft.delivery_state = selectedAddresses.state;
      this.draft.delivery_country = selectedAddresses.country;
      this.draft.delivery_phone = selectedAddresses.phone;
      this.draft.delivery_mobile = selectedAddresses.mobile;
      this.draft.delivery_email = selectedAddresses.email;
      this.draft.address_book_id = selectedAddresses.id;

      let i = 0;
      this.draft.delivery_address = [];
      addresses.forEach(address => {
          this.draft.delivery_address[i] = {
            delivery_name : address.name,
            delivery_company : address.company_name,
            delivery_branch : address.branch_name,
            delivery_department : address.department ? address.department.department_name:"",
            delivery_address_1 : address.address_1,
            delivery_address_2 : address.address_2,
            delivery_postal_code : address.postal_code,
            delivery_city : address.city,
            delivery_suburb : address.suburb,
            delivery_state : address.state,
            delivery_country : address.country,
            delivery_phone : address.phone,
            delivery_mobile : address.mobile,
            delivery_email : address.email,
            address_book_id : address.id
          };
          i++;
      });

      this.show_destination_suggestion = false;
    },*/
    populateDeliveryAddress(addresses){
      if(this.draft.entry_type == "single"){
        addresses = [addresses];
      }
      let selectedAddresses = addresses[0];
      console.log(selectedAddresses);
      this.draft.delivery_name = selectedAddresses.name;
      this.draft.delivery_company = selectedAddresses.company_name;
      this.draft.delivery_branch = selectedAddresses.branch_name;
      this.draft.delivery_department = selectedAddresses.department ? selectedAddresses.department.department_name : "";
      this.draft.delivery_address_1 = selectedAddresses.address_1;
      this.draft.delivery_address_2 = selectedAddresses.address_2;
      this.draft.delivery_postal_code = selectedAddresses.postal_code;
      this.draft.delivery_city = selectedAddresses.city;
      this.draft.delivery_suburb = selectedAddresses.suburb;
      this.draft.delivery_state = selectedAddresses.state;
      this.draft.delivery_country = selectedAddresses.country;
      this.draft.delivery_phone = selectedAddresses.phone;
      this.draft.delivery_mobile = selectedAddresses.mobile;
      this.draft.delivery_email = selectedAddresses.email;
      this.draft.address_book_id = selectedAddresses.id;

      this.mapDeliveryAddresses(addresses);
      this.delivery_addresses_count = this.draft.delivery_address.length;
      this.show_destination_suggestion = false;
    },
    mapDeliveryAddresses(addresses,selected_from_address_book = true){
      let i = 0;
      this.draft.delivery_address = [];
      addresses.forEach(address => {
          this.draft.delivery_address[i] = {
            delivery_name : address.name,
            delivery_company : address.company_name,
            delivery_branch : address.branch_name,
            delivery_department : address.department ? address.department.department_name:"",
            delivery_address_1 : address.address_1,
            delivery_address_2 : address.address_2,
            delivery_postal_code : address.postal_code,
            delivery_city : address.city,
            delivery_suburb : address.suburb,
            delivery_state : address.state,
            delivery_country : address.country,
            delivery_phone : address.phone,
            delivery_mobile : address.mobile,
            delivery_email : address.email,
            address_book_id : selected_from_address_book ? address.id : address.address_book_id ? address.address_book_id : address.id
          };
          i++;
      });
    },
    addressSelected(selectedAddresses){
        console.log(selectedAddresses); // You will get the address here
    },
    fetchAddresses(){
      this.axios.post("api/address/list")
        .then(response => {
          this.address_list = response.data.msg;
        })
        .catch(error => {
          console.log(error);
        });
    },
    closeModal(value){
      this.address_book[value.type] = false;
    },
    submit(){
      
      this.draft.delivery_address[0] = {
        delivery_name : this.draft.delivery_name,
        delivery_company : this.draft.delivery_company,
        delivery_branch : this.draft.delivery_branch,
        delivery_department : this.draft.delivery_department,
        delivery_address_1 : this.draft.delivery_address_1,
        delivery_address_2 : this.draft.delivery_address_2,
        delivery_postal_code : this.draft.delivery_postal_code,
        delivery_city : this.draft.delivery_city,
        delivery_suburb : this.draft.delivery_suburb,
        delivery_state : this.draft.delivery_state,
        delivery_country : this.draft.delivery_country,
        delivery_phone : this.draft.delivery_phone,
        delivery_mobile : this.draft.delivery_mobile,
        delivery_email : this.draft.delivery_email,
        address_book_id : this.draft.address_book_id ?this.draft.address_book_id : "htfjfgjhgjg"
      };

        console.log(this.draft);

      this.$refs.observer.validate();
      this.draft.is_draft = 0;

      this.submit_btn.disabled = true;
      this.submit_btn.value = "Submitting, Please wait";

      if(this.$route.params.id){
          this.axios.post("/api/draft/user/"+this.$route.params.id,this.draft)
          .then(response => {
                  console.log(response);
              this.toast.success(response.data.msg);
              this.viewconsignmentpopup_booking_id = response.data.id;
              // this.$router.push("/pages/ViewPending")
              this.submit_btn.disabled = false;
              this.submit_btn.value = "Submit";
          })
          .catch(error =>{
            console.log(error);
            this.submit_btn.disabled = false;
            this.submit_btn.value = "Submit";
          });  
      }else{
        this.axios.post("/api/draft/user",this.draft)
              .then(response => {
                  console.log(response);
                  this.viewconsignmentpopup_booking_id = response.data.id;
                  this.toast.success(response.data.msg);
                  this.submit_btn.disabled = false;
                  this.submit_btn.value = "Submit";
          })
          .catch(error =>{
            console.log(error);
            this.submit_btn.disabled = false;
            this.submit_btn.value = "Submit";
          });   
      }     
    },
    draftsubmit(){
      
      this.draft.delivery_address[0] = {
        delivery_name : this.draft.delivery_name,
        delivery_company : this.draft.delivery_company,
        delivery_branch : this.draft.delivery_branch,
        delivery_department : this.draft.delivery_department,
        delivery_address_1 : this.draft.delivery_address_1,
        delivery_address_2 : this.draft.delivery_address_2,
        delivery_postal_code : this.draft.delivery_postal_code,
        delivery_city : this.draft.delivery_city,
        delivery_suburb : this.draft.delivery_suburb,
        delivery_state : this.draft.delivery_state,
        delivery_country : this.draft.delivery_country,
        delivery_phone : this.draft.delivery_phone,
        delivery_mobile : this.draft.delivery_mobile,
        delivery_email : this.draft.delivery_email,
        address_book_id : this.draft.address_book_id ?this.draft.address_book_id : "htfjfgjhgjg"
      };

      this.$refs.observer.validate();
      this.draft.is_draft = 1;

        console.log(this.draft);

        this.save_btn.disabled = true;
        this.save_btn.value = "SAVING...";

      if(this.$route.params.id){
          this.axios.post("/api/draft/user/"+this.$route.params.id,this.draft)
          .then(response => {
            console.log(response);
            this.$router.push("/pages/Draft");
            this.toast.success(response.data.msg);
            this.save_btn.disabled = false;
            this.save_btn.value = "SAVE AS DRAFT";
          })
          .catch(error =>{
            console.log(error);
            this.save_btn.disabled = false;
            this.save_btn.value = "SAVE AS DRAFT";
          });  
      }else{
        this.axios.post("/api/draft/user",this.draft)
          .then(response => {
            console.log(response);
            this.$router.push("/pages/Draft");
            this.toast.success(response.data.msg);
            this.save_btn.disabled = false;
            this.save_btn.value = "SAVE AS DRAFT";
          })
          .catch(error =>{
            console.log(error);
            this.save_btn.disabled = false;
            this.save_btn.value = "SAVE AS DRAFT";
          });     
      } 

          
    },
    closeConsignment(){
      this.viewconsignmentpopup_booking_id = '';
      this.$router.push("/pages/ViewPending");
      this.courier = this.courier.map(element => {
        element.show_view_popup = false;
        return element;
      });
    },
  },
  watch:{
    'draft.mode_of_dispatch'(){
      this.fetchProviders();
    }
  }
};
</script>
<style scoped>
.service_details_container {
  padding: 20px;
}
.service_details_container > section {
  padding: 20px 0 0 0;
}
.service_details_container > section h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #06A5ED;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #06A5ED;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.radio_wrapper1 {
  width: 300px;
  height: 100px;
  background: #fcfcfc;
  display: flex;
  padding: 20px 0;
}
.radio_wrapper1 label {
  width: auto;
  height: 30px;

  display: block;
  margin: 5px;
}
.radio_wrapper1 label input[type="radio"] {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.radio_wrapper1 label span {
  width: auto;
  height: 20px;
  display: inline-block;
  margin-right: 30px;
  position: relative;
  top: -5px;
}

.radio_with_img_labels {
  width: 180px;
  height: 80px;
  background: #fff;
  color: #333;
  padding: 5px;
  margin: 10px 20px;
  display: inline-block;
}
.radio_with_img_labels img {
  display: block;
  margin: auto;
  height: 40px;
}
.radio_with_img_labels input[type="radio"] {
  width: 20px;
  height: 20px;
  display: block;
  margin: auto;
}

.special_panel {
  background: #fff;
  width: 100%;
}
.special_panel header {
  height: 40px;
  background: #333;
  color: #ccc;
  font-size: 16px;
  line-height: 40px;
  padding: 0 20px;
}
.special_panel .sp_body {
  background: #fff;
  padding: 20px;
}
.special_panel .sp_body .a_part {
  width: 50%;
  display: inline-block;
  position: relative;
  height: 100%;
}
.special_panel .sp_body .a_part .infos {
  padding: 20px 0;
  color: #333;
  font-size: 16px;
}
.special_panel .sp_body .a_part .view_rate_card {
  display: block;
  background: #06A5ED;
  color: #fff;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  right: 20px;
  bottom: -125px;
}
.label_and_element_wrapper.sp {
  width: 70%;
}
.lone_chk_label {
  padding: 20px;
  display: inline-block;
}
.lone_chk_label span {
  display: inline-block;
  position: relative;
  top: -5px;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.address_details_wrapper {
  width: 45%;
  height: auto;
  display: inline-block;
  margin: 0 2%;
}
.adjust_width_full_wrapper {
  width: 94%;
  height: auto;
  display: inline-block;
  margin: 0 2%;
}
.address_details_wrapper header {
  height: 60px;
}
.address_details_wrapper header h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: block;
  float: left;
}
.address_details_wrapper header .yellow_bt {
  font-size: 20px;
  padding: 0 10px;
  float: right;
  width: 200px;
  height: 40px;
  background: #063657;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  display: block;
}
.address_details_wrapper header .yellow_bt i {
  font-size: 24px;
}
.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
.selected_count {
  width: 130px;
  height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 40px;
  color: rgb(8, 82, 243);
  float: right;
  box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  float: right;
  margin-right: 20px;
}
.selected_count .material-icons {
  font-size: 40px;
  top: -1px;
  left: -10px;
  position: relative;
  margin-right: 0;
}
.selected_count span {
  display: inline-block;
  position: relative;
  top: -13px;
  left: -8px;
}
</style>
<style scoped>
.service_details_container {
  padding: 20px;
}
.service_details_container > section {
  padding: 20px 0 0 0;
}
.service_details_container > section h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #06A5ED;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #06A5ED;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.label_and_element_wrapper.sp {
  width: 33%;
}
.lone_chk_label {
  padding: 20px;
  display: block;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
}
.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
td.series_td {
  width: 220px;
}
table.tablce_cell_adj tr td {
  vertical-align: top;
  padding: 0;
}
table.tablce_cell_adj tr td input[type="checkbox"] {
  width: 20px;
  height: 20px;
  display: inline-block;
}
table.tablce_cell_adj tr td:nth-child(-n + 2) {
  width: auto;
}
table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(-n + 10) {
  width: 100px;
}
table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(n + 11) {
  width: 60px;
}
</style>